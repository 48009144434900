import { Link } from "gatsby";
import React from "react";
import DoctorList from "../about/DoctorList";
import Alert from "../Alert";
import BubbleSection from "../BubbleSection";
import Button from "../Button";

const DOCTORS = [
  {
    name: "Łukasz Galus",
    title: "specjalista onkologii klinicznej",
    description:
      "Absolwent Uniwersytetu Medycznego im. Karola Marcinkowskiego w Poznaniu na Wydziale Lekarskim. Jest specjalistą w dziedzinie onkologii klinicznej. Pracuje w Szpitalu Klinicznym im. Heliodora Święcickiego Uniwersytetu Medycznego im. Karola Marcinkowskiego w Poznaniu na Oddziale Onkologii Klinicznej i Doświadczalnej oraz w Wielkopolskim Centrum Onkologii. Członek PTOK (Polskiego Towarzystwa Onkologii Klinicznej) oraz ESMO (European Society of Medical Oncology). Autor publikacji w czasopismach naukowych. Bierze udział w wielu badaniach klinicznych (próbach eksperymentalnych) oceniających nowe leki stosowane u chorych na nowotwory złośliwe. Jego zainteresowania i główny zakres działalności klinicznej obejmuje pacjentów cierpiących z powodu nowotworów skóry w szczególności czerniaka. Strefa zainteresowań naukowych obejmuje nowe strategie leczenia systemowego w tym immunoterapię nowotworów i możliwości jej wykorzystania w praktyce leczniczej. Rolą onkologa klinicznego jest integracja wszystkich metod leczenia przeciwnowotworowego - planowanie i koordynacja leczenia chirurgicznego, radioterapii i terapii systemowej dlatego onkolog pełni kluczową rolę w procesie diagnostyczno-terapeutycznym pacjenta z chorobą nowotworową.",
    city: "Poznań",
    img: "lukasz_galus.png",
  },
  {
    name: "Anna Kopczyńska",
    title: "specjalista onkologii klinicznej",
    description:
      "Ukończyła Uniwersytet Medyczny im. Karola Marcinkowskiego w Poznaniu na Wydziale Lekarskim. Obecnie pracuje w Szpitalu Klinicznym Przemienienia Pańskiego w ramach specjalizacji z onkologii klinicznej.  Jest członkiem Polskiego Towarzystwa Onkologii Klinicznej. W swojej pracy wykorzystuje najnowsze doniesienia naukowe, uczestniczy w konferencjach i szkoleniach poświęconych między innymi nowotworom skóry. Posiada doświadczenie w ocenie dermatoskopowej znamion. Uczestniczy w profilaktycznych akcjach społecznych w kierunku czerniaka skóry.",
    city: "Poznań",
    img: "anna_kopczynska.jpeg",
  },
  {
    name: "dr Marlena Janiczek-Polewska",
    title: "specjalizuje się w onkologii klinicznej",
    description:
      "Ukończyła studia na kierunku lekarskim na Uniwersytecie Mikołaja Kopernika W Toruniu. W trakcie specjalizacji z onkologii klinicznej oraz studiów podyplomowych z medycyny estetycznej. Tytuł doktora nauk medycznych i nauk o zdrowiu uzyskała w 2020 roku na Uniwersytecie Medycznym im. Karola Marcinkowskiego w Poznaniu. Autorka publikacji naukowych z zakresu onkologii klinicznej i patomorfologii. Ukończyła studia podyplomowe w zakresie prawa medycznego, zarządzania w ochronie zdrowia oraz badań klinicznych i metodologii badań naukowych. Członkini Polskiego Towarzystwa Onkologicznego, Onkologii Klinicznej i Europejskiej Szkoły Onkologicznej. Uczestniczy także w licznych konferencjach, szkoleniach i kursach poświęconych nowotworom skóry i dermatoskopii.",
    city: "Poznań",
    img: "marlena_janiczek.png",
  },
];

const Doctors = ({ className = "", graph, button = null }) => {
  const AlertButtonComponent = button || (
    <Link to="/o-nas" title="Poznaj naszych lekarzy">
      Poznaj wszystkich
    </Link>
  );

  const AlertButton = () => (
    <Button>
      <AlertButtonComponent />
    </Button>
  );

  return (
    <div className={`${className} gallery`}>
      <BubbleSection introText="" h2="Nasz zespół" color="blue" reverse>
        <div className="container">
          <div className="cards">
            <DoctorList data={DOCTORS} graph={graph} />
          </div>
          <Alert button={AlertButton}>
            Nasz zespół tworzą doświadczeni lekarze, którzy wspólnie, z pasją
            działają na rzecz <strong>zmniejszenia umieralności</strong> z
            powodu nowotworów skóry w Polsce.
          </Alert>
        </div>
      </BubbleSection>
    </div>
  );
};

export default Doctors;
